import React from 'react'


const Blog = (props) => {
    
    
    
    return ( 
        <>  

            <div className="text-light center">
                <p>nothing to see here yet</p>
            </div>
            
        </>
     );
}
 
export default Blog;